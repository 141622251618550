.copy-to-clipboard-button {
    position: relative;
    border: none;
    background-color: transparent;
    padding-left: 0;

    span {
        display: flex;
        gap: 10px;

        width: 140px;
        color: var(--colour-clipboard-button-text);
        background-color: var(--colour-clipboard-button);
        border: none;
        border-radius: 5px;
        font-size: 15px;
        padding: 5px 10px 3px 10px;
        margin: 10px 0 20px 0;
        cursor: pointer;

        &:hover, &:active {
            background-color: var(--colour-clipboard-button-hover);
        }
    }

    &-copied-notice {
        pointer-events: none;
        position: absolute;
        top: 10px;
        left: 120px;
        padding: 5px 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--colour-text-default);
        border-radius: 5px;
        box-shadow: 2px 2px 6px var(--colour-box-shadow);
        background-color: var(--colour-background);
        font-size: 15px;
        font-weight: bold;

        transition-property: opacity;
        transition-duration: 0.5s;
        
        @keyframes fadeOut {
            0% {opacity: 1;}
            100% {opacity: 0;} 
        } 

        &-hidden {
            opacity: 0;
        }
    }
    

    svg {
        padding-top: 2px;
        fill: var(--colour-clipboard-button-text);
    }
}