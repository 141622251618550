.el-buttons {
    &-container {
        display: grid;
        grid-template-columns: 150px 1fr 150px;
    }

    &-button {
        background-color: transparent;
        border: none;
        color: var(--colour-earlier-later-enabled);
        font-size: 15px;
        font-weight: bold;
        text-decoration: underline;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    &-disabled {
        cursor: default;
        color: var(--colour-earlier-later-disabled);
    }

    &-button:hover {
        color: var(--colour-earlier-later-hover);
    }

    &-disabled:hover {
        color: var(--colour-earlier-later-disabled);
    }

    &-later {
        grid-column: 1;
        text-align: left;
    }

    &-earlier {
        grid-column: 3;
        text-align: right;
    }
}