$toggle-height: 25px;
$toggle-width: 50px;

.page-container {
    //day mode styles
    .night-mode-toggle {
        &-slider {
            transition: all 500ms;
        }
    }
}


.page-container--night-mode {
    //night mode styles
    .night-mode-toggle {
        &-slider {
            transform: translateX($toggle-width - $toggle-height - 1px);
            transition: all 500ms;
        }
    }
}

.night-mode-toggle {
    height: $toggle-height;
    width: $toggle-width;
    background-color: var(--colour-mode);
    border-radius: 5px;
    padding: 2px;
    border: none;

    &-slide-area {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &-slider {
        aspect-ratio: 1/1;
        height: 100%;
        background-color: var(--colour-mode-secondary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    &-slide-area:hover {
        cursor: pointer;

        .night-mode-toggle-slider {
            background-color: var(--colour-mode-secondary-hover);;
        }
    }

    &-slider-icon {
        font-size: 12px;
        color: var(--colour-mode-icon);
    }
}

/*Mobile only*/
@media only screen and (max-width: 888px) {
    .night-mode-toggle {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}