.tab {
	padding: 5px 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
    border-radius: 5px 5px 0 0;
    background-color: transparent;
    border: none;

    &-container {
        display: flex;
    }

    &:hover {
        .tab-text {
            color: var(--colour-text-tab-hover);
        }
    }

    &-text {
        margin: 0;
        color: var(--colour-text-tab);
        text-decoration: underline;
        font-weight: bold;
    }

    &-selected {
        background-color: var(--colour-background);

        &:hover {
            .tab-text {
                color: var(--colour-text-tab-selected-hover);
            }
        }

        .tab-text {
            color: var(--colour-text-tab-selected);
            text-decoration: none;
            cursor: default;
        }
    }
}

/*Desktop only*/
@media only screen and (min-width: 889px) {
    .tab {
        &-text {
        font-size: 20px;
        }
    }
}

/*Mobile only*/
@media only screen and (max-width: 889px) {
    .tab {
        &-text {
            font-size: 17px;
        }

        &-container {
            justify-content: center;
        }
    }
}