@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran&display=swap');

@import "mainPage";
@import "singlePostPage";
@import "tabs";
@import "title";
@import "post";
@import "earlierLaterButtons";
@import "copyToClipboardButton";
@import "colours";
@import "nightModeToggle";
@import "loadingSpinner";

body, html, #root {
	width: 100%;
	height: 100%;
	margin: 0;
}

.page-container {
	width: 100%;
	min-height: 100%;
	display: flex;
	font-family: 'Catamaran', sans-serif;
	display: flex;
	justify-content: center;
	background-color: var(--colour-background)
}

.top-bar-content {
	display: flex;
	padding-top: 10px
}

.title-container {
	flex-grow: 1;

	a {
		text-decoration: none;
	}
}

button {
    font-family: 'Catamaran', sans-serif;
	margin: 0;
}

.main-container {
	flex-grow: 1;
	background-color: var(--colour-background-secondary);
	max-width: 1000px;
	
	display: grid;
}

/*Desktop only*/
@media only screen and (min-width: 889px) {
	.main-container {
		margin: 15px;
		grid-template-rows: 100px 1fr;
        padding: 0 30px 30px 30px;
        border-radius: 5px;
	}
}
 
/*Mobile only*/
@media only screen and (max-width: 888px) {
	.main-container {
		grid-template-rows: 70px 1fr;
	}
}

