.post {
	color: var(--colour-text-default);

    p, h3 {
        margin: 0;
        padding: 0;
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-date {
        font-size: 15px;
        color: var(--colour-text-secondary);
    }

    &-link {
        color: var(--colour-text-link);
    }

    // specify whole class to override any default styles
    .post-title {
        font-size: 16px;
        color: var(--colour-text-default);

        border: none;

        padding: 0;
        background: none;
        text-align: left;
    }

    &-image-container {
        a, img, iframe {
            width: 100%;
            border-radius: 5px;
        }
    }

    &-video-container {
        iframe {
            width: 100%;
            border-radius: 5px;
        }
    }

    /*Desktop only*/
    @media only screen and (min-width: 889px) {
        &-image-container {
            max-width: 600px;
            max-height: 600px;
        }

        &-video-container {
            max-width: 600px;
        }

        // specify whole class to override any default styles
        .post-title {
            width: 400px;
        }
    }
    
    /*Mobile only*/
    @media only screen and (max-width: 888px) {
        &-image-container {
            width: 100%;
        }

        &-video-container {
            width: 100%;
        }
    }
}

.post-single-post {
    padding: 15px; 
}

.post-main-page {
    .post {
        &-title {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: var(--colour-link-hover);
            }
        }
    }
}
