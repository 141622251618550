.main-page {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    
    .tab-container {
        grid-row: 1;
        flex-basis: 40px;
    }

    &-content {
        flex-grow: 1;
        background-color: var(--colour-background);
        padding: 15px;
    }
}

@media only screen and (min-width: 889px) {
	.main-page {
		&-content {
            border-radius: 5px;
    
            &-first-tab-selected {
                border-radius: 0 5px 5px 5px;
            }
        }
	}
}

/*Mobile only*/
@media only screen and (max-width: 888px) {
    .main-page {
		&-content {
            padding-bottom: 50px;
        }
	}
}