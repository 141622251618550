@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran&display=swap");
.main-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.main-page .tab-container {
  grid-row: 1;
  flex-basis: 40px;
}
.main-page-content {
  flex-grow: 1;
  background-color: var(--colour-background);
  padding: 15px;
}

@media only screen and (min-width: 889px) {
  .main-page-content {
    border-radius: 5px;
  }
  .main-page-content-first-tab-selected {
    border-radius: 0 5px 5px 5px;
  }
}
/*Mobile only*/
@media only screen and (max-width: 888px) {
  .main-page-content {
    padding-bottom: 50px;
  }
}
.single-post {
  height: 100%;
  background-color: var(--colour-background);
  /*Desktop only*/
}
@media only screen and (min-width: 889px) {
  .single-post {
    border-radius: 5px;
  }
}

.tab {
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0 0;
  background-color: transparent;
  border: none;
}
.tab-container {
  display: flex;
}
.tab:hover .tab-text {
  color: var(--colour-text-tab-hover);
}
.tab-text {
  margin: 0;
  color: var(--colour-text-tab);
  text-decoration: underline;
  font-weight: bold;
}
.tab-selected {
  background-color: var(--colour-background);
}
.tab-selected:hover .tab-text {
  color: var(--colour-text-tab-selected-hover);
}
.tab-selected .tab-text {
  color: var(--colour-text-tab-selected);
  text-decoration: none;
  cursor: default;
}

/*Desktop only*/
@media only screen and (min-width: 889px) {
  .tab-text {
    font-size: 20px;
  }
}
/*Mobile only*/
@media only screen and (max-width: 889px) {
  .tab-text {
    font-size: 17px;
  }
  .tab-container {
    justify-content: center;
  }
}
.title {
  font-family: "Gluten", cursive;
  color: var(--colour-title);
}
.title-container {
  margin-top: 10px;
  grid-row: 1;
  display: flex;
  align-items: center;
}

/*Desktop only*/
@media only screen and (min-width: 889px) {
  .title {
    font-family: "Gluten", cursive;
    font-size: 70px;
  }
}
/*Mobile only*/
@media only screen and (max-width: 888px) {
  .title {
    font-size: 50px;
    margin: 10px;
  }
  .title-container {
    justify-content: center;
  }
}
.post {
  color: var(--colour-text-default);
  /*Desktop only*/
  /*Mobile only*/
}
.post p, .post h3 {
  margin: 0;
  padding: 0;
}
.post-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.post-date {
  font-size: 15px;
  color: var(--colour-text-secondary);
}
.post-link {
  color: var(--colour-text-link);
}
.post .post-title {
  font-size: 16px;
  color: var(--colour-text-default);
  border: none;
  padding: 0;
  background: none;
  text-align: left;
}
.post-image-container a, .post-image-container img, .post-image-container iframe {
  width: 100%;
  border-radius: 5px;
}
.post-video-container iframe {
  width: 100%;
  border-radius: 5px;
}
@media only screen and (min-width: 889px) {
  .post-image-container {
    max-width: 600px;
    max-height: 600px;
  }
  .post-video-container {
    max-width: 600px;
  }
  .post .post-title {
    width: 400px;
  }
}
@media only screen and (max-width: 888px) {
  .post-image-container {
    width: 100%;
  }
  .post-video-container {
    width: 100%;
  }
}

.post-single-post {
  padding: 15px;
}

.post-main-page .post-title {
  text-decoration: underline;
  cursor: pointer;
}
.post-main-page .post-title:hover {
  color: var(--colour-link-hover);
}

.el-buttons-container {
  display: grid;
  grid-template-columns: 150px 1fr 150px;
}
.el-buttons-button {
  background-color: transparent;
  border: none;
  color: var(--colour-earlier-later-enabled);
  font-size: 15px;
  font-weight: bold;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.el-buttons-disabled {
  cursor: default;
  color: var(--colour-earlier-later-disabled);
}
.el-buttons-button:hover {
  color: var(--colour-earlier-later-hover);
}
.el-buttons-disabled:hover {
  color: var(--colour-earlier-later-disabled);
}
.el-buttons-later {
  grid-column: 1;
  text-align: left;
}
.el-buttons-earlier {
  grid-column: 3;
  text-align: right;
}

.copy-to-clipboard-button {
  position: relative;
  border: none;
  background-color: transparent;
  padding-left: 0;
}
.copy-to-clipboard-button span {
  display: flex;
  gap: 10px;
  width: 140px;
  color: var(--colour-clipboard-button-text);
  background-color: var(--colour-clipboard-button);
  border: none;
  border-radius: 5px;
  font-size: 15px;
  padding: 5px 10px 3px 10px;
  margin: 10px 0 20px 0;
  cursor: pointer;
}
.copy-to-clipboard-button span:hover, .copy-to-clipboard-button span:active {
  background-color: var(--colour-clipboard-button-hover);
}
.copy-to-clipboard-button-copied-notice {
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 120px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colour-text-default);
  border-radius: 5px;
  box-shadow: 2px 2px 6px var(--colour-box-shadow);
  background-color: var(--colour-background);
  font-size: 15px;
  font-weight: bold;
  transition-property: opacity;
  transition-duration: 0.5s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.copy-to-clipboard-button-copied-notice-hidden {
  opacity: 0;
}
.copy-to-clipboard-button svg {
  padding-top: 2px;
  fill: var(--colour-clipboard-button-text);
}

.page-container {
  --colour-medium-blue: #3199f5;
  --colour-blue: #1072c7;
  --colour-light-light-light-blue: #b3dbfc;
  --colour-light-light-blue: #83c4fc;
  --colour-light-blue: #71b8f5;
  --colour-dark-blue: #164f82;
  --colour-dark-dark-blue: #0d2d4a;
  --colour-dark-grey: #242424;
  --colour-light-dark-grey: #353535;
  --colour-light-light-dark-grey: #555555;
  --colour-grey: #666666;
  --colour-light-grey: #aaaaaa;
  --colour-light-light-grey: #dddddd;
  --colour-white: white;
  --colour-light-yellow: #e7e89b;
  --colour-black: black;
}

.page-container {
  --colour-background: var(--colour-white);
  --colour-background-secondary: var(--colour-light-blue);
  --colour-box-shadow: var(--colour-dark-grey);
  --colour-title: var(--colour-white);
  --colour-link-hover: var(--colour-dark-dark-blue);
  --colour-text-default: var(--colour-dark-grey);
  --colour-text-secondary: var(--colour-grey);
  --colour-text-link: var(--colour-blue);
  --colour-text-tab: var(--colour-white);
  --colour-text-tab-hover: var(--colour-dark-grey);
  --colour-text-tab-selected: var(--colour-dark-grey);
  --colour-text-tab-selected-hover: var(--colour-light-blue);
  --colour-clipboard-button: var(--colour-blue);
  --colour-clipboard-button-hover: var(--colour-dark-dark-blue);
  --colour-clipboard-button-text: var(--colour-white);
  --colour-mode:var(--colour-light-light-light-blue);
  --colour-mode-secondary: var(--colour-medium-blue);
  --colour-mode-secondary-hover: var(--colour-light-blue);
  --colour-mode-icon: var(--colour-light-yellow);
  --colour-earlier-later-enabled: var(--colour-dark-grey);
  --colour-earlier-later-disabled: var(--colour-light-grey);
  --colour-earlier-later-hover: var(--colour-blue);
  --colour-loading-spinner: var(--colour-light-blue);
}

.page-container--night-mode {
  --colour-background: var(--colour-dark-grey);
  --colour-background-secondary: var(--colour-dark-dark-blue);
  --colour-box-shadow: var(--colour-black);
  --colour-title: var(--colour-light-grey);
  --colour-link-hover: var(--colour-blue);
  --colour-text-default: var(--colour-light-grey);
  --colour-text-secondary: var(--colour-grey);
  --colour-text-link: var(--colour-light-blue);
  --colour-text-tab: var(--colour-light-grey);
  --colour-text-tab-hover: var(--colour-grey);
  --colour-text-tab-selected: var(--colour-light-grey);
  --colour-text-tab-selected-hover: var(--colour-light-blue);
  --colour-clipboard-button: var(--colour-dark-blue);
  --colour-clipboard-button-hover: var(--colour-dark-dark-blue);
  --colour-clipboard-button-text: var(--colour-light-light-grey);
  --colour-mode: var(--colour-light-dark-grey);
  --colour-mode-secondary: var(--colour-dark-blue);
  --colour-mode-secondary-hover: var(--colour-blue);
  --colour-mode-icon: var(--colour-light-grey);
  --colour-earlier-later-enabled: var(--colour-light-grey);
  --colour-earlier-later-disabled: var(--colour-light-light-dark-grey);
  --colour-loading-spinner: var(--colour-dark-blue);
}

.page-container .night-mode-toggle-slider {
  transition: all 500ms;
}

.page-container--night-mode .night-mode-toggle-slider {
  transform: translateX(24px);
  transition: all 500ms;
}

.night-mode-toggle {
  height: 25px;
  width: 50px;
  background-color: var(--colour-mode);
  border-radius: 5px;
  padding: 2px;
  border: none;
}
.night-mode-toggle-slide-area {
  width: 100%;
  height: 100%;
  position: relative;
}
.night-mode-toggle-slider {
  aspect-ratio: 1/1;
  height: 100%;
  background-color: var(--colour-mode-secondary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.night-mode-toggle-slide-area:hover {
  cursor: pointer;
}
.night-mode-toggle-slide-area:hover .night-mode-toggle-slider {
  background-color: var(--colour-mode-secondary-hover);
}
.night-mode-toggle-slider-icon {
  font-size: 12px;
  color: var(--colour-mode-icon);
}

/*Mobile only*/
@media only screen and (max-width: 888px) {
  .night-mode-toggle {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading-spinner-icon {
  font-size: 50px;
  color: var(--colour-loading-spinner);
  animation: spin 1s linear infinite;
}
.loading-spinner-hidden {
  display: none;
}

body, html, #root {
  width: 100%;
  height: 100%;
  margin: 0;
}

.page-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  font-family: "Catamaran", sans-serif;
  display: flex;
  justify-content: center;
  background-color: var(--colour-background);
}

.top-bar-content {
  display: flex;
  padding-top: 10px;
}

.title-container {
  flex-grow: 1;
}
.title-container a {
  text-decoration: none;
}

button {
  font-family: "Catamaran", sans-serif;
  margin: 0;
}

.main-container {
  flex-grow: 1;
  background-color: var(--colour-background-secondary);
  max-width: 1000px;
  display: grid;
}

/*Desktop only*/
@media only screen and (min-width: 889px) {
  .main-container {
    margin: 15px;
    grid-template-rows: 100px 1fr;
    padding: 0 30px 30px 30px;
    border-radius: 5px;
  }
}
/*Mobile only*/
@media only screen and (max-width: 888px) {
  .main-container {
    grid-template-rows: 70px 1fr;
  }
}

