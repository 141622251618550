.title {
	font-family: 'Gluten', cursive;
	color: var(--colour-title);

    &-container {
		margin-top: 10px;
        grid-row: 1;
        display: flex;
        align-items: center;
    }
}

/*Desktop only*/
@media only screen and (min-width: 889px) {
	.title {
		font-family: 'Gluten', cursive;
		font-size: 70px;
	}
}
 
/*Mobile only*/
@media only screen and (max-width: 888px) {
	.title {
		font-size: 50px;
		margin: 10px;

        &-container {
            justify-content: center;
        }
	}
}

