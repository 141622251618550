//base-colours
.page-container {
	--colour-medium-blue: #3199f5;
	--colour-blue: #1072c7;
	--colour-light-light-light-blue: #b3dbfc;
	--colour-light-light-blue: #83c4fc;
	--colour-light-blue: #71b8f5;
	--colour-dark-blue: #164f82;
	--colour-dark-dark-blue: #0d2d4a;
	--colour-dark-grey: #242424;
	--colour-light-dark-grey: #353535;
	--colour-light-light-dark-grey: #555555;
	--colour-grey: #666666;
	--colour-light-grey: #aaaaaa;
	--colour-light-light-grey: #dddddd;
    --colour-white: white;
	--colour-light-yellow: #e7e89b;
	--colour-black: black;
}

// Light mode
.page-container {
	--colour-background: var(--colour-white);
	--colour-background-secondary: var(--colour-light-blue);

	--colour-box-shadow: var(--colour-dark-grey);

	--colour-title: var(--colour-white);
	--colour-link-hover: var(--colour-dark-dark-blue);
	--colour-text-default: var(--colour-dark-grey);
	--colour-text-secondary: var(--colour-grey);
	--colour-text-link: var(--colour-blue);

	--colour-text-tab: var(--colour-white);
	--colour-text-tab-hover: var(--colour-dark-grey); 
	--colour-text-tab-selected: var(--colour-dark-grey);
	--colour-text-tab-selected-hover: var(--colour-light-blue);

	--colour-clipboard-button: var(--colour-blue);
	--colour-clipboard-button-hover: var(--colour-dark-dark-blue);
	--colour-clipboard-button-text: var(--colour-white);

	--colour-mode:var(--colour-light-light-light-blue);
	--colour-mode-secondary: var(--colour-medium-blue);
	--colour-mode-secondary-hover: var(--colour-light-blue);
	--colour-mode-icon: var(--colour-light-yellow);

	--colour-earlier-later-enabled: var(--colour-dark-grey);
	--colour-earlier-later-disabled: var(--colour-light-grey);
	--colour-earlier-later-hover: var(--colour-blue);

	--colour-loading-spinner: var(--colour-light-blue);
}

// Night mode
.page-container--night-mode {
	--colour-background: var(--colour-dark-grey);
	--colour-background-secondary: var(--colour-dark-dark-blue);
	
	--colour-box-shadow: var(--colour-black);

	--colour-title: var(--colour-light-grey);
	--colour-link-hover: var(--colour-blue);
	--colour-text-default: var(--colour-light-grey);
	--colour-text-secondary: var(--colour-grey);
	--colour-text-link: var(--colour-light-blue);

	--colour-text-tab: var(--colour-light-grey);
	--colour-text-tab-hover: var(--colour-grey); 
	--colour-text-tab-selected: var(--colour-light-grey);
	--colour-text-tab-selected-hover: var(--colour-light-blue);

	--colour-clipboard-button: var(--colour-dark-blue);
	--colour-clipboard-button-hover: var(--colour-dark-dark-blue);
	--colour-clipboard-button-text: var(--colour-light-light-grey);

	--colour-mode: var(--colour-light-dark-grey);
	--colour-mode-secondary: var(--colour-dark-blue);
	--colour-mode-secondary-hover: var(--colour-blue);
	--colour-mode-icon: var(--colour-light-grey);

	--colour-earlier-later-enabled: var(--colour-light-grey);
	--colour-earlier-later-disabled: var(--colour-light-light-dark-grey);

	--colour-loading-spinner: var(--colour-dark-blue);
}